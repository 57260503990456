const state = () => ({
    dialog: false,
    dialogNewAds: false,
    dialogEditAds: false,
    newAds: { type: '', link: '', status: false, startDate: new Date(), endDate: new Date(), user: {}, qrcode: '', name: '', tyme: '', showQr: null },
    defaultAds: { type: '', link: '', status: false, startDate: new Date(), endDate: new Date(), user: {}, qrcode: '', name: '', tyme: '', showQr: null },
    editedAds: { type: '', link: '', status: false, startDate: new Date(), endDate: new Date(), user: {}, qrcode: '', name: '', tyme: '', showQr: null},
    showChangeMedia: false,
    data: [
        // {
        //   adsId: Date.now(),
        //   region: 159,
        //   user: { name: "Busnellinho" },
        //   playlist: { _id: "6080fa6c8b1f001728d97c86" },
        //   status: false,
        // },
    ]
})
const mutations = {
    prepareAdsToDelete(state, ads) {
        state.editedAds = ads
    },
    addAds(state, ads) {
        state.data.push(ads)
        this.commit('success', "Ads criado com sucesso")
        state.dialogNewAds = false
        state.newAds = {}
    },
    editAds(state, value) {
        state.editedAds = value
        state.dialogEditAds = true
    },
    newAds(state) {
        state.newAds.user = this.state.user
        state.dialogNewAds = true
    },
    reset(state) {
        state.newAds = Object.assign({}, state.defaultAds)
        state.editAds = Object.assign({}, state.defaultAds)
    },
    setLink(state, url) {
        state.newAds.link = url
    }
}
export default { state, mutations }