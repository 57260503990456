import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/layouts/layout.vue'),
    redirect: '/dashboards',
    children: [
      {
        path: 'dashboards',
        name: 'Dashboards',
        component: () => import('@/views/Dashboards/Dashboards.vue'),
      },
      {
        path: 'advertisements',
        name: 'Advertisements',
        component: () => import('@/views/Advertisements/Advertisements.vue'),
        redirect: '/advertisements/list',
        children: [
          {
            path: 'list',
            name: "ListAds",
            component: () => import('@/views/Advertisements/list.vue'),
          },
          {
            path: 'edit/:id',
            name: "editedAds",
            component: () => import('@/views/Advertisements/edit/edit.vue'),
          },
          {
            path: 'add',
            name: "newAds",
            component: () => import('@/views/Advertisements/add/add.vue'),
          }
        ]
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/News/News.vue'),
        redirect: '/news/list',
        children: [
          {
            path: 'add/:id?',
            name: 'AddNews',
            component: () => import('@/views/News/add.vue')
          },
          {
            path: 'list',
            name: 'ListNews',
            component: () => import('@/views/News/list.vue')
          }
        ]
      },

      {
        path: 'schedule',
        name: 'Schedule',
        component: () => import('@/views/Schedule/Schedule.vue'),
      },

      {
        path: 'playlists',
        name: 'Playlists',
        component: () => import('@/views/Playlist/Playlist.vue'),
        redirect: '/playlists/list',
        children: [
          {
            path: 'add',
            name: 'addPlaylist',
            component: () => import('@/views/Playlist/add/add.vue')
          },
          {
            path: 'edit/:id',
            name: 'editPlaylist',
            component: () => import('@/views/Playlist/edit/edit.vue')
          },
          {
            path: 'list',
            name: 'ListPlaylist',
            component: () => import('@/views/Playlist/list.vue')
          }
        ]
      },
      {
        path: 'totens',
        name: 'Totens',
        redirect: '/totens/list',
        component: () => import('@/views/Totens/Totens.vue'),
        children:[
          {
            path: 'list',
            name: 'ListTotens',
            component: () => import('@/views/Totens/list.vue')
          }
        ]

      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/Users.vue'),
      },
      {
        path: 'access-control',
        name: 'AccessControl',
        component: () => import('@/views/AccessControl.vue'),
      },
      {
        path: 'profiles',
        name: 'Profiles',
        component: () => import('@/views/Profiles.vue'),
      }
    ],
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/layouts/login.vue'),
    meta: { auth: false }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/layouts/forgotPassword.vue'),
    meta: { auth: false }
  },
  {
    path: '/update-password/:token',
    name: 'UpdatePassword',
    component: () => import('@/views/layouts/updatePassword.vue'),
    meta: { auth: false }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_NODE_ENV,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!localStorage.getItem('token')) {
      return next({ path: '/login' })
    }
    return next()
  }
  next()
})

export default router
