const state = () => ({
    dialog: false,
    dialogNewTotem: false,
    dialogEditTotem: false,
    newTotem: {},
    editedTotem: {},
    data: [
        // {
        //   totemId: Date.now(),
        //   region: 159,
        //   user: { name: "Busnellinho" },
        //   playlist: { _id: "6080fa6c8b1f001728d97c86" },
        //   status: false,
        // },
      ]
})
const mutations = {
    prepareTotemToDelete(state, totem){
        state.editedTotem = totem
    },
    addTotem(state, totem){
        state.data.push(totem)
        this.commit('success', "Totem criado com sucesso")
        state.dialogNewTotem = false
        state.newTotem = {}
    },
    editTotem(state, value){
        state.editedTotem = value
        state.dialogEditTotem = true
    },
    setEditedTotem(state, value){
        state.editedTotem = value
    },
    newTotem(state){
        state.newTotem.user = this.state.user
        state.dialogNewTotem = true
    }
}
export default {state,mutations}