const state = () => ({timeStage1: 10000, timeWrite: 40, timeStage2: 5000})
const mutations = {
  speed(state){
    state.timeStage1 = 1000
    state.timeWrite = 10
    state.timeStage2 = 1000
  },
  normal(state){
    state.timeStage1 = 10000
    state.timeWrite = 40
    state.timeStage2 = 5000
  }
}
export default { state, mutations, namespaced: true }