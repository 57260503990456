import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    alert1: false,
    alert1Timeout: 3000,
    alert1Message: '',
    alert1Icon: '',
    alert1Color: '',
    searched: false,
    search: '',
    editedProfile: false,
    userForm: {
      timeout: 3000,
      snackbar: false,
      valid: false,
      showModal: true,
      loading: false,
      color: "#A3A3A3",
      showColors: false,
      rulesName: [val => (val || []).length > 0 || 'Nome é obrigatório!'],
      rulesEmail: [val => (val || []).length > 0 || "Email é obrigatório!"],
      rulesType: [val => (val || '').length > 0 || "Tipo de conta é obrigatório!"],
    },
    news: {
      _id: '',
      text: '',
      img: '',
      copyright: '',
      logo: '',
      color: '',
      textColor: '',
      link: '',
      startDate: '',
      endDate: '',
      previewNews: { imgs: [], divs: [], data:'' },
      loadingNews: false,
      edited: false,
      changeCrowller: false,
      status: false,
      time: '',
      userSelected: '',
      templates: [],
      paragraphs: [],
      mediaType: 'Padrão'
    },
    originalNews: {
      _id: '',
      text: '',
      img: '',
      copyright: '',
      logo: '',
      color: '',
      textColor: '',
      link: '',
      startDate: '',
      endDate: '',
      previewNews: { imgs: [], divs: [] },
      loadingNews: false,
      edited: false,
      changeCrowller: false,
      status: false,
      time: '',
      userSelected: '',
      templates: [],
      paragraphs: [],
      mediaType: 'Padrão'
    },

    routes: [],
    descriptions: [],
    users: [],
    editedIndex: false,
    editedUser: { name: '', email: '', type: '', color: '#BDBBBBFF', logo: '', descriptions: [], templates:[] },
    editedUserJson: '',
    editedUserReset: { name: '', email: '', type: '', color: '#BDBBBBFF', logo: '' },
    accountTypes: ["Administrador", "Anunciante", "Aprovador", "Parceiro"],
    types: [],
    dataTable: {
      showModal: false
    },
    drawer: null,
    user: {
      name: '',
      logo: '',
      type: '',
      color: '',
      textColor: '',
      email: "",
      password: '',
      confirmPassword: '',
      descriptions: [],
      routes: []
    },
    snackbar: false,
    snackbarMessage: '',
    timeoutSnackbar: 3000
  },
  mutations: {
    setUserSelected(state, value){
      state.news.userSelected = value
    },
    success(state, message){
      state.alert1= true,
      state.alert1Message= message,
      state.alert1Icon= 'mdi-check-bold'
      state.alert1Color = 'green'
    },
    failed(state, message){
      state.alert1= true,
      state.alert1Message= message,
      state.alert1Icon= 'mdi-close-octagon'
      state.alert1Color = 'red'
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer
    },
    setUser(state, user) {
      state.user = user
    },
    setLoading(state, data){
      state.loading = data
    }
  },
  actions: {
  },
  modules
})
