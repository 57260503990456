const state = () => ({
  items: [],
  "Template 1": {
    img: '',
    title: '',
    stop: false,
  },

  "Template 2": {
    text: '',
    stop: false,
  },

  "Template 3": {
    img: '',
    title:'',
    text: '',
    stop: false,
  },

  "Template 4": {
    text: '',
    stop: false,
  },
  "Template 5": {
    stop: false,
  },
})
const mutations = {
  setTemplate1(state, value) {
    state['Template 1'] = value
  },
  setTemplate2(state, value) {
    state['Template 2'] = value
  },
  setTemplate3(state, value) {
    state['Template 3'] = value
  },
  setTemplate4(state, value) {
    state['Template 4'] = value
  },
  setTemplate5(state, value) {
    state['Template 5'] = value
  },
  setStop1(state, value) {
    state['Template 1'].stop = value
  },
  setStop2(state, value) {
    state['Template 2'].stop = value
  },
  setStop3(state, value) {
    state['Template 3'].stop = value
  },
  setStop4(state, value) {
    state['Template 4'].stop = value
  },
  setStop5(state, value) {
    state['Template 5'].stop = value
  },
  setStop(state, value) {
    state['Template 1'].stop = value
    state['Template 2'].stop = value
    state['Template 3'].stop = value
    state['Template 4'].stop = value
    state['Template 5'].stop = value
  },
  setItems(state, value){
    state.items = value
  }
}

const actions = {}

export default { actions, mutations, state, namespaced: true }