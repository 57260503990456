const state = () => ({
    selectedDate: '',
    selectedHours: '00:00',
    showDialog: false,
    playlistSelected: {},
    startDate: '',
    endDate: '',
    events: [],
    step: 1,
    editedSchedule: {},
    regionsSelected: [],
    totens: [],
    regions: [],
    totensSelected: [],
    repeat: null
})
const mutations = {
    reset(state) {
        state.selectedDate = ''
        state.selectedHours = '00:00'
        state.showDialog = false
        state.playlistSelected = {}
        state.startDate = ''
        state.endDate = ''
        state.step = 1
        state.editedSchedule = {}
        state.regionsSelected = []
        state.totens = []
        state.regions = []
        state.totensSelected = [],
        state.repeat= null
    },
    setSelectedHours(state, data){
        state.selectedHours = data
    }
}

const actions = {}

export default { actions, mutations, state, namespaced: true }