const state = () => ({
    type: 'Notícias',
    data: [], //data tabl
    totalItems: 0,
    itemsNews: [], // anuncios ou notícias
    originalItemsNews: [],
    filtered: false,
    itemsAds: [],
    originalItemsAds: [],
    itemsSelected: [{ text: 1, _id: '1133', time: 0 }],
    editedPlaylist: {},
    previewAds: {
        link: '',
        type: ''
    },
    preview: {
        showQr: null,
        color: '',
        textColor: '',
        img: '',
        copyright: '',
        text: '',
        logo: '',
        link: '',
    },
    resetPlaylist: {
        itemsSelected: [{ text: 1, _id: '1133', time: 0 }],
        previewAds: {
            link: '',
            type: ''
        },
        preview: {
            showQr: null,
            color: '',
            textColor: '',
            img: '',
            copyright: '',
            text: '',
            logo: '',
            link: '',
        },
    }
})
const mutations = {
    setData(state, data){
        state.data = data
    },
    setTotalItems(state, totalItems){
        state.totalItems = totalItems
    },
    filter(state, event){
        if(event.key.length != 1 && !['Backspace', 'Enter'].includes(event.key)) return event.preventDefault()
        if(!state.filtered) {
            state.originalItemsNews = Object.assign([], state.itemsNews)
            state.originalItemsAds = Object.assign([], state.itemsAds)
            state.filtered = true
        }
        const value = event.target.value.toLowerCase()
        if(!value && (state.itemsAds.length == state.originalItemsAds.length) && state.itemsNews.length == state.originalItemsNews.length) return 
        state.itemsNews = state.originalItemsNews.filter(item => item.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(value) || item.user.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(value))
        state.itemsAds = state.originalItemsAds.filter(item => item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(value))
    }
}

const actions = {
    
}

export default { actions, mutations, state, namespaced: true }